import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function categoryHandlerSidebar(props, emit) {
  // ------------------------------------------------
  // serviceCategoryLocal
  // ------------------------------------------------

  const serviceCategoryLocal = ref(JSON.parse(JSON.stringify(props.category.value)))
  const showImageDesktop = ref(null)
  showImageDesktop.value = props.category.value.showImage

  const resetServiceCategoryLocal = () => {
    serviceCategoryLocal.value = JSON.parse(JSON.stringify(props.category.value))
  }
  watch(props.category, () => {
    resetServiceCategoryLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const postCategoryData = JSON.parse(JSON.stringify(serviceCategoryLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.category.value.id) emit('update-category', serviceCategoryLocal.value)
    else emit('add-category', serviceCategoryLocal.value)

    // Close sidebar
    emit('update:is-service-category-handler-sidebar-active', false)
  }

  return {
    serviceCategoryLocal,
    resetServiceCategoryLocal,

    // UI
    showImageDesktop,

    onSubmit,
  }
}
